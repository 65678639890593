import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class EventBusService {
  public showPopupEvent = new EventEmitter();
  public showNoMonthFoundEvent = new EventEmitter();
  public showNoDomainFoundEvent = new EventEmitter();
  public showFeedbackButtonEvent = new EventEmitter();
  public showAdvancedFiltersEvent = new EventEmitter();
  public showServerErrorEvent = new EventEmitter();
  public showWhatsNewEvent = new EventEmitter<boolean>();
  public downloadCsvClickPopupEvent = new EventEmitter<boolean>();
  public showQuotaErrorEvent = new EventEmitter<{
    errMsg: string;
    redirect: boolean;
  }>();
  public loadFacetsEvent = new EventEmitter();
  public clearAllAppsFiltersEvent = new EventEmitter<boolean>();
  public addAppFilterEvent = new EventEmitter();
  public scrollAppDetailsEvent = new EventEmitter();
  public updateAppDetails = new EventEmitter<boolean>();
  public updateDomainDetails = new EventEmitter<boolean>();
  public updateEyeNumFollowing = new EventEmitter<boolean>();
  public watchlistUpdate = new EventEmitter();
  public showDownloadCsvLimitPopupEvent = new EventEmitter<boolean>();
  public showDownloadDeductionPopupEvent = new EventEmitter<number>();
  public invitePopupEvent = new EventEmitter<boolean>();
  public sendInviteEmailEvent = new EventEmitter<{
    email: string;
    message: string;
  }>();
  public setInvitingEvent = new EventEmitter<boolean>();

  constructor() {}

  public openScheduleADemo() {
    window.open('https://info.pixalate.com/scheduledemo', '_blank');
  }

  public showInvitePopup() {
    this.invitePopupEvent.emit(true);
  }

  public hideInvitePopup() {
    this.invitePopupEvent.emit(false);
  }

  public sendInviteEmail(email: string, message: string = '') {
    this.sendInviteEmailEvent.emit({ email, message });
  }

  public scrollAppDetailsSection(section: string) {
    this.scrollAppDetailsEvent.emit(section);
  }

  public clearAllAppsFilters() {
    this.clearAllAppsFiltersEvent.emit(true);
  }

  public addAppFilter(filter: any) {
    this.addAppFilterEvent.emit(filter);
  }

  public loadFacets(facets: any) {
    this.loadFacetsEvent.emit(facets);
  }

  public hideFeedback() {
    this.showFeedbackButtonEvent.emit(false);
  }

  public showFeedback() {
    this.showFeedbackButtonEvent.emit(true);
  }

  public showServerError() {
    this.showServerErrorEvent.emit(true);
  }

  public showPopup() {
    this.showPopupEvent.emit(true);
  }

  public showDownloadCsvLimitPopup() {
    this.showDownloadCsvLimitPopupEvent.emit(true);
  }

  public showDownloadDeductionPopup(value: number) {
    this.showDownloadDeductionPopupEvent.emit(value);
  }

  public showQuotaError(errMsg: string, redirect: boolean = true) {
    this.showQuotaErrorEvent.emit({ errMsg, redirect });
  }

  public hidePopup() {
    this.showPopupEvent.emit(false);
  }

  public showAdvancedFilters() {
    this.showAdvancedFiltersEvent.emit(true);
  }

  public hideAdvancedFilters() {
    this.showAdvancedFiltersEvent.emit(false);
  }

  public showNoMontFoundPopup(loadingMonth: string) {
    this.showNoMonthFoundEvent.emit(loadingMonth);
  }

  public showNoDomainFoundPopup(loadingDomain: string) {
    this.showNoDomainFoundEvent.emit(loadingDomain);
  }

  public downloadCsvClickOnPopup() {
    this.downloadCsvClickPopupEvent.emit(true);
  }

  public notifyWatchlistUpdate(evt: any) {
    this.watchlistUpdate.emit(evt);
  }

  public startInviting() {
    this.setInvitingEvent.emit(false);
  }
}
