import { Component } from '@angular/core';
import { EventBusService } from '../../../services/EventBusService';
import { User } from 'app/models/User';
import { Router } from '@angular/router';
import { TrackingService } from 'app/services/tracking.service';

@Component({
  selector: 'app-fna-popup',
  templateUrl: './fna-popup.component.html',
  styleUrls: ['./fna-popup.component.css']
})
export class FnaPopupComponent {
  isFreemium: boolean;
  showNoMonthFound: boolean;
  showPopup: boolean;
  loadingMonth: string;
  showNoDomainFound: boolean;
  loadingDomain: string;
  showWhatsNew: boolean;
  showServerError: boolean;
  showDownloadCsvLimitPopup: boolean;
  showDownloadDeductionPopup: boolean;
  showQuotaError: boolean;
  quotaErrMsg: string;
  redirectToPti: boolean;
  downloadDecutionValue: number;
  showInvitePopup: boolean;
  inviteEmail: string;
  inviteMessage: string;
  emailInvite: string;
  inviting: boolean;

  constructor(
    private _router: Router,
    private _userService: User,
    private eventBus: EventBusService,
    private tracking: TrackingService
  ) {
    this.isFreemium = this._userService.isFreemium();
    this.eventBus.showPopupEvent.subscribe(value => this.showPopupEvent(value));
    this.eventBus.showNoMonthFoundEvent.subscribe(value =>
      this.showNoMonthFoundPopup(value)
    );
    this.eventBus.showNoDomainFoundEvent.subscribe(value =>
      this.showNoDomainFoundPopup(value)
    );
    this.eventBus.showServerErrorEvent.subscribe(value =>
      this.showServerErrorPopup()
    );
    this.eventBus.showWhatsNewEvent.subscribe(value => {
      if (value) {
        // jQuery('#whatsNewModal').modal('show');
      }
    });
    this.eventBus.showDownloadCsvLimitPopupEvent.subscribe(value => {
      this.showDownloadCsvLimitPopup = true;
    });
    this.eventBus.showDownloadDeductionPopupEvent.subscribe(value => {
      this.downloadDecutionValue = value;
      this.showDownloadDeductionPopup = true;
    });
    this.eventBus.showQuotaErrorEvent.subscribe(value => {
      if (!this.showQuotaError) {
        // prevent multiple calls
        this.showQuotaError = true;
        this.quotaErrMsg = value.errMsg;
        this.redirectToPti = value.redirect;
        const props = {
          isFreemium: this.isFreemium,
          appUrl: window.location.href
        };
        this.tracking.eventTrack('quota_error', props);
      }
    });
    this.eventBus.invitePopupEvent.subscribe((status: boolean) => {
      this.showInvitePopup = status;
      this.inviting = false;
      if (status) {
        // We clear previously inputed email, message
        this.inviteEmail = '';
        this.inviteMessage = '';
      }
    });
    this.eventBus.setInvitingEvent.subscribe((invitingStatus: boolean) => {
      this.inviting = invitingStatus;
    });
  }

  close() {
    this.showPopup = false;
  }

  showPopupEvent(value) {
    this.showPopup = value;
  }

  showServerErrorPopup() {
    this.showServerError = true;
  }

  showNoMonthFoundPopup(loadingMonth: string) {
    this.showNoMonthFound = true;
    this.loadingMonth = loadingMonth;
  }

  showNoDomainFoundPopup(loadingDomain: string) {
    this.showNoDomainFound = true;
    this.loadingDomain = loadingDomain;
  }

  contact() {
    window.location.href = 'mailto:sales-external@pixalate.com';
  }

  downloadCsvClick() {
    this.showDownloadDeductionPopup = false;
    this.eventBus.downloadCsvClickOnPopup();
  }

  inviteClick() {
    // console.log( this.inviteMessage );
    if (!this.inviting) {
      this.inviting = true;
      this.eventBus.sendInviteEmail(this.inviteEmail, this.inviteMessage);
    }
  }

  quotaErrorOkClick() {
    this.showQuotaError = false;
    if (this.redirectToPti) {
      this._router.navigate(['/rankings/app/publisher-trust-index/']);
    }
  }
}
