/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { AuthService } from './auth.service';
import { BaseApiService } from './base-api-service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../models/User';
import { WebsiteRating } from '../models/WebsiteRating';
import { shareReplay } from 'rxjs/operators';

declare let jQuery: any;

@Injectable()
export class AppService extends BaseApiService {
  country = 'GLOBAL';
  region = 'GLOBAL';
  os = 'ios';
  device = 'GLOBAL';
  month: string;
  appDetailsCachedObservables: any = {};
  appWebsiteCacheData: any = {};
  availableCountriesCache: any = {};
  appIconCoordinates: any;
  // Param to be excluded
  excludeFacetParam: string;
  availableMonthsCache: any = {};

  // previousUrl when clicked on popup
  previousUrl: string;

  discoveryDataCache: any = {};
  facetsDataCache: any = {};
  reportDataCache: any;

  isFollower: boolean;

  constructor(
    private http: HttpClient,
    private userService: User,
    private auth: AuthService
  ) {
    super();
    this.isFollower = false;
  }

  setFollowing(isFollower: boolean) {
    this.isFollower = isFollower;
  }

  storeAppIconCoordinates(coordinates: any) {
    this.appIconCoordinates = coordinates;
  }

  setRegion(region: string) {
    this.region = region;
  }

  setCountry(country: string) {
    this.country = country;
  }

  setOs(os: string) {
    this.os = os;
  }

  setDevice(device: string) {
    this.device = device;
  }

  setMonth(month: string) {
    this.month = month;
  }

  getAllData(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string,
    monthParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;
    const month = monthParam ? monthParam : this.month;

    let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
    if (this.userService.appInsightsCountryEnabled()) {
      query =
        `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND country = '${country}' ` +
        `AND deviceType = '${deviceType}'`;
    }
    if (month && month.length > 0) {
      query += ` AND month = '${month}'`;
    }

    const urlString = `${this.getReportBaseUrl()}/getWidget?q=${query}&reportId=mobileAppMrt&widgetId=mobileAppSummary&`;
    return this.getCachedObservable(urlString);
  }

  getAvailableFilters(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;

    let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
    if (
      this.userService.appInsightsCountryEnabled() &&
      country &&
      country.length > 0
    ) {
      query += ` AND country = '${country}' `;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?reportId=mobileAppMrt&` +
      `widgetId=availableFilters&q=${query}`;

    return this.getCachedObservable(urlString);
  }

  getCachedObservable(urlString: string) {
    const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
    let cachedObservable = this.appDetailsCachedObservables[cacheKey];

    if (!cachedObservable) {
      cachedObservable = this.http.get(urlString).pipe(shareReplay());
      this.appDetailsCachedObservables[cacheKey] = cachedObservable;
    }

    return cachedObservable.toPromise();
  }

  getWebsiteRatings() {
    return new Promise<WebsiteRating[]>(resolve => {
      const urlString = `${this.getMrtBaseUrl()}/blobs/website-ratings`;
      const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
      const cacheData = this.appWebsiteCacheData[cacheKey];
      if (cacheData) {
        resolve(JSON.parse(JSON.stringify(cacheData)));
      } else {
        this.http.get<WebsiteRating[]>(urlString).subscribe(res => {
          const filteredRes = res.filter(fres => fres.appId);
          this.storeCache(filteredRes, this.appWebsiteCacheData, cacheKey);
          resolve(filteredRes);
        });
      }
    });
  }

  getAppScopeData(
    appid: string,
    os?: string,
    deviceOption?: string,
    regionOption?: string,
    countryParam?: string,
    monthParam?: string
  ) {
    const device = os ? os : this.os;
    const country = countryParam ? countryParam : this.country;
    const region = regionOption ? regionOption : this.region;
    const deviceType = deviceOption ? deviceOption : this.device;
    const month = monthParam ? monthParam : this.month;

    return new Promise(resolve => {
      let query = `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND deviceType = '${deviceType}'`;
      if (this.userService.appInsightsCountryEnabled()) {
        query =
          `WHERE appId = '${appid}' AND os = '${device}' AND region = '${region}' AND country = '${country}' ` +
          `AND deviceType = '${deviceType}'`;
      }
      if (month && month.length > 0) {
        query += ` AND month = '${month}'`;
      }
      const urlString = `${this.getReportBaseUrl()}/getWidget?q=${query}&reportId=mobileAppMrt&widgetId=availableCountries&`;
      const cacheKey = `${this.auth.getAuthState()}:${urlString}`;

      if (this.availableCountriesCache[cacheKey]) {
        const cacheData = this.availableCountriesCache[cacheKey];
        resolve(JSON.parse(JSON.stringify(cacheData)));
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.availableCountriesCache, cacheKey);
          resolve(res);
        });
      }
    });
  }

  getAvailableMonths(reportId = 'mobileAppMrt'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }

  searchForApps(start: number, limit: number, filterString: string) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/searchForApps?start=${start}&limit=${limit}`;

      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (
        this.discoveryDataCache[urlString] &&
        urlString.indexOf('userId') < 0
      ) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getAppStats(filterStrings: string[] = []) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl('2018')}/appStats?`;
      if (this.month) {
        filterStrings.push(`month=${this.month}`);
      }
      urlString += filterStrings.join('&');
      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getFacets(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString =
        `${this.getRatingsBaseUrl()}/getAppFacets?facet=appStore&` +
        `facet=pixalateRating&facet=hasAdsTxt&facet=isExistInAppStore&` +
        `facet=isBlacklisted&facet=isPrivateDomain&facet=appDescriptionBrandSafetyRating&facet=appContentBrandSafetyRating&` +
        `facet=facebookRating&facet=linkedInRating&facet=hasInAppPurchases&` +
        `facet=twitterRating&facet=spoofingRating&facet=isIncentivizedApp&` +
        `facet=locationMaskingRating&facet=viewabilityRating&facet=coppaRisk&facet=coppaTargetChildrenRisk&` +
        `facet=hasVideoCapability&facet=hasAds&facet=adultContentRating&` +
        `facet=drugContentRating&facet=alcoholContentRating&facet=hateSpeechRating&` +
        `facet=offensiveContentRating&facet=gamblingContentRating&facet=topDevice&` +
        `facet=topVideoAdSize&facet=hasVideoCapability&facet=hasAds&facet=overallIvtPermissionRisk&` +
        `facet=violenceContentRating&facet=medicalContentRating&facet=topDisplayAdSize&` +
        `facet=hasUserGeneratedContent&facet=hasPrivacyLink&facet=hasTermsOfServiceLink&facet=mfaRating&` +
        `facet=ownerDomain`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    param: string[],
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppFacets`;

      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl()}/getAppFacets?region=${selectedRegion}`;
      }

      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }

      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }

      if (selectedDevice) {
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? 'device=smartphone&device=tablet'
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(res => {
          this.storeCache(res, this.facetsDataCache, urlString);
          resolve(res);
        });
      }
    });
  }

  getImportFacets(bulkUploadReportId: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getAppFacets?bulkUploadReportId=${bulkUploadReportId}&facet=region&facet=device&facet=appStore`;

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  downloadCsv(reportId: string, filterString: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=${reportId}&${filterString}&isAsync=true`;
      this.httpGet(urlString, resolve);
    });
  }

  checkImportQuota() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  importCSV(formData: FormData, importParam) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      return this.http
        .post(`${this.getRatingsBaseUrl()}/importApps?${importParam}`, formData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            resolve(err);
          }
        );
    });
  }

  getImportList() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listAppImport`;
      this.http.get(urlString).subscribe(res => {
        this.storeCache(res, this.discoveryDataCache, urlString);
        resolve(res);
      });
    });
  }

  deleteImportedFile(bulkUploadReportId: string) {
    if (bulkUploadReportId) {
    }
  }

  getReportData() {
    return new Promise(resolve => {
      if (!this.reportDataCache) {
        const urlString = `${this.getRatingsBaseUrl(
          '2018'
        )}/loadReport?reportId=appSummary`;
        return this.http.get(urlString).subscribe(response => {
          this.reportDataCache = response;
          resolve(response);
        });
      } else {
        resolve(this.reportDataCache);
      }
    });
  }

  // https://dev-ratings2-srvc.pixalate.com//services/2012/Report/getImportStatus?bulkUploadReportId=15375175328351
  // https://dev-ratings2-srvc.pixalate.com//services/2012/Ratings/getImportStatus?bulkUploadReportId=1537517532835
  getImportStatus(bulkUploadReportId: string) {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/getImportStatus?bulkUploadReportId=${bulkUploadReportId}`;
      return this.http.get(urlString).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  savePreset(formData: any) {
    return new Promise((resolve, reject) => {
      return this.http
        .post(`${this.getRatingsBaseUrl('2018')}/saveReport`, formData)
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            reject(err);
          }
        );
    });
  }

  listPresets(groupName: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/listReport?group=${groupName}`;
      this.httpGet(urlString, resolve);
    });
  }

  loadPreset(reportId: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/loadReport?reportId=${reportId}`;
      this.httpGet(urlString, resolve);
    });
  }

  deletePreset(reportId: string) {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/deleteReport?reportId=${reportId}`;
      this.httpGet(urlString, resolve);
    });
  }

  /**
   * Helper function to get and resolve
   *
   * @param urlString
   * @param resolve
   */
  private httpGet(urlString: string, resolve: any): void {
    this.http.get(urlString).subscribe(response => {
      resolve(response);
    });
  }
}
