import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { User } from '../models/User';
import { AuthService, AuthState } from './auth.service';

declare let toastr: any;

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private userService: User,
    private auth: AuthService,
    private router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const authState = this.auth.getAuthState();

    this.userService.establishSession();

    let permissions = route.data.permissions as Array<string>;
    let msg =
      'You need access permission to use that part of the application. Please contact your account administrator.';

    switch (authState) {
      case AuthState.Anonymous:
        msg =
          'You need access permission to use that part of the application. <br><br>' +
          '<a class="btn btn-primary btn-block btn-schedule-a-demo no-content" href="https://www.pixalate.com/scheduledemo" target="_blank">Schedule a Demo!</a>';

      case AuthState.Authenticated:
        if (this.userService.hasPermissions(permissions)) {
          return true;
        }
        break;
    }

    toastr.error(msg, { timeOut: 10000, extendedTimeOut: 0 });

    return this.router.parseUrl('/rankings');
  }
}
