/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { AuthService } from './auth.service';
import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';

declare let jQuery: any;

@Injectable()
export class DomainService extends BaseApiService {
  public region = 'US';
  public device = 'Desktop';
  public month: string;

  domainIconCoordinates: any;
  domainDetailsCache: any = {};

  availableMonthsCache: any = {};
  domainCachedObservables: any = {};
  facetsDataCache: any = {};
  discoveryDataCache: any = {};
  isFollowing = false;

  constructor(private http: HttpClient, private auth: AuthService) {
    super();
  }

  storeDomainDetailsCache(domain: string, cache: any) {
    this.storeCache(cache, this.domainDetailsCache, domain);
  }

  storeDomainIconCoordinates(coordinates: any) {
    this.domainIconCoordinates = coordinates;
  }

  setRegion(region: string): void {
    this.region = region;
  }

  setDevice(device: string): void {
    this.device = device;
  }

  setMonth(month: string): void {
    this.month = month;
  }

  setFollowing(isFollowing: boolean) {
    this.isFollowing = isFollowing;
  }

  private makeSectionRequest(
    domain: string,
    api: string,
    deviceType: string,
    country: string,
    month?: string
  ) {
    let query = '';
    if (deviceType && deviceType !== '') {
      query += ` AND deviceType = '${deviceType}' `;
    }

    if (month && month !== '') {
      query += ` AND month = '${month}' `;
    }

    let urlString = `${this.getReportBaseUrl()}/getWidget?q=WHERE adDomain='${domain}' AND countryCode='${country}' ${query} &`;
    urlString += `reportId=mrt&widgetId=domainSummary&sectionId=${api}`;
    return this.http.get(urlString);
  }

  getAllData(domain: string, regionParam?: string, deviceParam?: string) {
    const region = regionParam ? regionParam : this.region;
    const device = deviceParam ? deviceParam : this.device;
    const month = this.month;

    let urlString = '';
    let query = '';
    if (device !== '') {
      query += ` AND deviceType = '${device}' `;
    }
    if (month && month !== '' && month !== 'last30Days') {
      query += ` AND month = '${month}' `;
    }

    urlString = `${this.getReportBaseUrl()}/getWidget?q=WHERE adDomain='${domain}' AND countryCode='${region}' ${query} &`;
    urlString += `reportId=mrt&widgetId=domainSummary`;

    return this.getCachedObservable(urlString);
  }

  getAvailableFilters(
    domain: string,
    regionParam?: string,
    deviceParam?: string
  ) {
    const region = regionParam ? regionParam : this.region;
    const device = deviceParam ? deviceParam : this.device;

    let query = `WHERE adDomain='${domain}' AND countryCode='${region}'`;
    if (device !== '') {
      query += ` AND deviceType = '${device}'`;
    }

    const urlString =
      `${this.getReportBaseUrl('2018')}/getWidget?reportId=mrt&` +
      `widgetId=availableFilters&q=${query}`;

    return this.getCachedObservable(urlString);
  }

  getCachedObservable(urlString: string) {
    const cacheKey = `${this.auth.getAuthState()}:${urlString}`;
    let cachedObservable = this.domainCachedObservables[cacheKey];

    if (!cachedObservable) {
      cachedObservable = this.http.get(urlString).pipe(shareReplay());
      this.domainCachedObservables[cacheKey] = cachedObservable;
    }

    return cachedObservable.toPromise();
  }

  getOverallData(
    domain: string,
    region: string,
    device: string,
    month: string
  ) {
    return new Promise(resolve => {
      const api = 'overview';
      this.makeSectionRequest(domain, api, device, region, month).subscribe(
        res => {
          resolve(res);
        }
      );
    });
  }

  getFacets(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString =
        `${this.getRatingsBaseUrl()}/getDomainFacets?facet=risk&facet=brandSafetyRisk&facet=isVideoAvailable&` +
        `facet=isAdsDotTxtExist&facet=primaryDevice&facet=dayOfWeek&facet=adultContentRating&facet=hasTermsOfServiceLink&` +
        `facet=alcoholContentRating&facet=drugContentRating&facet=hateContentRating&facet=trafficSource&facet=mfaRating&` +
        `facet=facebookRating&facet=linkedinRating&facet=twitterRating&facet=isSubDomain&facet=hasPrivacyLink&facet=ownerDomain&` +
        `facet=hasCookieBanner`;
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(JSON.parse(JSON.stringify(this.facetsDataCache[urlString])));
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  getFacetsBySearch(
    selectedDevice: string,
    selectedRegion: string,
    isSubDomain: string,
    param: Array<string>,
    paramSearch: string,
    paramSearchValue: string,
    filterString?: string
  ) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getDomainFacets?nocache=${Math.random()}`;

      if (selectedRegion) {
        urlString = `${this.getRatingsBaseUrl()}/getDomainFacets?region=${selectedRegion}`;
      }

      if (param && param.length > 0) {
        for (const p of param) {
          urlString += `&facet=${p}`;
        }
      }

      if (
        paramSearch &&
        paramSearchValue &&
        paramSearch.length > 0 &&
        paramSearchValue.length > 0
      ) {
        urlString += `&${paramSearch}=${paramSearchValue}`;
      }

      if (selectedDevice) {
        const selectedDeviceString =
          selectedDevice === 'smartphone-tablet'
            ? 'device=smartphone&device=tablet'
            : `device=${selectedDevice}`;
        if (selectedDevice && selectedDevice.length > 0) {
          urlString = urlString.concat(`&${selectedDeviceString}`);
        }
      }

      urlString = urlString.concat(`&isSubDomain=${isSubDomain}`);

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  getImportFacets(bulkUploadReportId: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getDomainFacets?bulkUploadReportId=${bulkUploadReportId}&facet=region&facet=device&facet=isSubDomain`;

      if (this.facetsDataCache[urlString]) {
        resolve(this.facetsDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.facetsDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }

  checkImportQuota() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance?reportId=summary`;
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  searchForDomains(start: number, limit: number, filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/searchForDomains?start=${start}&limit=${limit}&month=${
        this.month
      }`;

      if (this.month) {
        urlString = `${urlString}&month=${this.month}`;
      }

      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  importCSV(formData: FormData, importParam) {
    const headers = new Headers();
    headers.append('Content-type', 'application/octet-stream');

    return new Promise(resolve => {
      return this.http
        .post(
          `${this.getRatingsBaseUrl()}/importDomains?${importParam}`,
          formData
        )
        .subscribe(
          res => {
            resolve(res);
          },
          err => {
            resolve(err);
          }
        );
    });
  }

  getImportList() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl('2018')}/listDomainImport`;
      this.http.get(urlString).subscribe(
        res => {
          this.storeCache(res, this.discoveryDataCache, urlString);
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getReportData() {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/loadReport?reportId=summary`;
      return this.http.get(urlString).subscribe(
        response => {
          resolve(response);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getDropdownItems(type: string, name: string, start: number, limit: number) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl()}/getMetadata?type=${type}&start=${start}&limit=${limit}`;
      if (name && name.length > 0) {
        urlString = urlString.concat(`&name=${name}`);
      }
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getAvailableMonths(reportId = 'search'): Promise<any> {
    if (!this.availableMonthsCache[reportId]) {
      this.availableMonthsCache[reportId] = new Promise((resolve, reject) => {
        const urlString = `${this.getRatingsBaseUrl()}/getWidget?reportId=${reportId}&widgetId=availableMonths`;
        this.http.get(urlString).subscribe(
          res => {
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      });
    }
    return this.availableMonthsCache[reportId];
  }

  downloadCsv(filterString: string) {
    return new Promise((resolve, reject) => {
      const urlString = `${this.getRatingsBaseUrl()}/getExportUri?reportId=search&${filterString}&isAsync=true`;
      this.http.get(urlString).subscribe(
        res => {
          resolve(res);
        },
        error => {
          reject(error);
        }
      );
    });
  }

  getDomainStats(filterString: string) {
    return new Promise((resolve, reject) => {
      let urlString = `${this.getRatingsBaseUrl(
        '2018'
      )}/stats?nocache=${Math.random()}`;
      if (this.month) {
        urlString += `&month=${this.month}`;
      }
      if (filterString && filterString.length > 0) {
        urlString = urlString.concat(`&${filterString}`);
      }

      if (this.discoveryDataCache[urlString]) {
        resolve(this.discoveryDataCache[urlString]);
      } else {
        this.http.get(urlString).subscribe(
          res => {
            this.storeCache(res, this.discoveryDataCache, urlString);
            resolve(res);
          },
          error => {
            reject(error);
          }
        );
      }
    });
  }
}
