import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api-service';
import { HttpClient } from '@angular/common/http';
import { User } from 'app/models/User';
import { UserInfo } from '../models/UserInfo';

@Injectable()
export class QuotaService extends BaseApiService {
  private userInfo: UserInfo;

  constructor(private http: HttpClient, private _userService: User) {
    super();
    this.userInfo = this._userService.getIdentity();
  }

  getQuotaUsage() {
    return new Promise(resolve => {
      const urlString = `${this.getRatingsBaseUrl()}/accountBalance`;
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }

  getActivityLogs(start = 0, limit = 15) {
    return new Promise(resolve => {
      let urlString = `${this.getRatingsBaseUrl(
        '2020'
      )}/getActivityLogs?start=${start}&limit=${limit}`;
      if (this.userInfo && this.userInfo.clientId) {
        urlString += `&clientId=${this.userInfo.clientId}`;
      }
      this.http.get(urlString).subscribe(res => {
        resolve(res);
      });
    });
  }
}
