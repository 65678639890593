import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './services/authguard-service';
import { User } from './models/User';
import { EnterpriseGuard } from './guards/enterprise-guard';
import { MetaGuard } from './guards/meta.guard';
import { PermissionGuard } from './services/permission-guard-service';

const appRoutes: Routes = [];

export const appRoutingProviders: any[] = [
  User,
  AuthGuard,
  EnterpriseGuard,
  MetaGuard,
  PermissionGuard
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes,
  { relativeLinkResolution: 'legacy' }
);
